.OrderPageWrapper {
  margin-top: 2rem;

  .actionBar {
    justify-content: flex-end;
    margin-bottom: 1rem;
    select {
      width: 15%;
      margin-left: 1rem;
    }
  }
}

.orderDetailsWrapper {
  @include container;
}
.cancelledOrder {
  visibility: 50% !important;
  background: rgb(255, 129, 129);
  opacity: 40%;
}

.trackerWrapper {
  display: flex;
  border: $border;
  padding: 1rem;
  text-align: center;
  justify-content: space-around;
  margin-top: 3rem;
  i {
    background: rgb(138, 138, 138);
    color: white;
    padding: 1.5rem;
    font-size: 1.5rem;
    border-radius: $radius;
    margin-bottom: 1rem;
  }
}

.OrderHistoryWrapper {
  @include container;
  .filters {
    display: flex;
    justify-content: flex-end;
  }
}

.order-footer {
  display: flex;
  justify-content: flex-end;

  &-main {
    width: 30%;
  }
}
