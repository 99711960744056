.testimonial {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    border: $border;
    background: white;
    padding: 1rem;
    height: 100%;

    margin-top: 1rem;

    &-header {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
    }

    &-image {
        height: 6rem;
        width: 6rem;
        border-radius: 50%;

        img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    }

    &-name {
        font-size: 1.125rem;
        font-weight: 500;
    }

    &-role {
        font-size: 0.9rem;
        color: color(text-light);
    }

    &-message {
        font-size: 0.9rem;
        line-height: 160%;
    }
}

.web {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    margin-bottom: 8rem;

    .input-error {
        color: red;
        font-size: 14px;
    }

    &-header {
        position: sticky;
        top: 0rem;
        display: flex;
        flex-direction: column;
        // gap: 0.62rem;
        background-color: #f4fbfd;
        padding: 0.5rem;
        padding-right: 1rem;
        z-index: 50;
    }

    &-title {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        justify-content: space-between;
    }

    &-btons {
    }

    &-desc {
        color: color(text-light);
        font-size: 0.825rem;
        max-width: 60%;
    }

    &-form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &_item {
            background-color: color(white);
            padding: 1rem;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &--header {
                display: flex;
                flex-direction: column;
            }

            &--title {
                font-size: 1.25rem;
                color: color(text-dark);
                font-weight: 500;
            }

            &--desc {
                font-size: 0.825rem;
                color: color(text-extra-light);
            }

            &--inputs {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }

        &_img {
            height: 15rem;
            margin-top: 1rem;

            img {
                height: 100%;
                object-fit: contain;
            }
        }

        &_container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &_lhead {
            display: flex;
            flex-direction: column;
        }

        &_label {
            font-weight: 500;
        }

        &_desc {
            font-size: 0.825rem;
            color: color(text-extra-light);
        }

        &_indiv {
        }

        &_input {
            resize: none !important;
            font-size: 0.85rem !important;
        }
    }
}

.appt {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    margin-bottom: 8rem;

    .input-error {
        color: red;
        font-size: 14px;
    }

    &-header {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        // gap: 0.62rem;
        background-color: #f4fbfd;
        padding: 0.5rem;
        padding-right: 1rem;
        z-index: 50;
    }

    &-title {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        justify-content: space-between;
    }

    &-btons {
    }

    &-desc {
        color: color(text-light);
        font-size: 0.825rem;
        max-width: 60%;
    }

    &-form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &_item {
            background-color: color(white);
            padding: 1rem;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        &_container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &_lhead {
            display: flex;
            flex-direction: column;
        }

        &_label {
            font-weight: 500;
        }

        &_indiv {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: baseline;
        }

        &_input {
            resize: none !important;
            font-size: 0.85rem !important;
            max-height: 40px !important;
        }
    }
}
