.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: $h1;
}
.h2 {
  font-size: $h2;
}

.color {
  &-primary {
    color: color(primary, base);
  }
  &-gray {
    &1 {
      color: color(gray, one);
    }
    &2 {
      color: color(gray, two);
    }
    &3 {
      color: color(gray, three);
    }
    &4 {
      color: color(gray, four);
    }
  }
}

//custom desing system colors

// .strongUp {
//   text-transform: uppercase;
//   color: color(secondary, base);
// }
// .strongDown {
//   font-weight: $font-regular;
// }

// .cpm {
//   color: color(primary, base);
// }
// .cpmt {
//   color: color(primary, tint);
// }
// .cpmt2 {
//   color: color(primary, tint2);
// }
// .bpmt {
//   background: color(primary, tint);
// }
// .csc {
//   color: color(secondary, base);
// }
// .csu {
//   color: color(success, base);
// }
// .bsu {
//   background: color(success, base);
// }
// .csut {
//   color: color(success, tint);
// }
// .bsut {
//   background-color: color(success, tint);
// }
// .cwr {
//   color: color(warning, base);
// }
// .cwrt {
//   color: color(warning, tint);
// }
// .bwrt {
//   background: color(warning, tint);
// }
// .cdng {
//   color: color(danger, base);
// }
// .bdngt {
//   background: color(danger, tint);
// }

// flexbetween

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .pagination {
//   justify-content: flex-end;
// }

// .page-link {
//   color: color(primary, base) !important;
// }
// .page-item.active .page-link {
//   z-index: 3;
//   color: #fff !important;
//   background-color: color(primary, base) !important;
//   border-color: color(primary, base) !important;
// }

//modal body here

// .modalBody {
//   padding: 1rem;
//   text-align: center;
//   font-size: $text-xlg;
//   &-title {
//     text-align: center;
//     margin-bottom: 1rem;
//   }
// }

//tabs navigation

// .nav-pills .nav-link {
//   background: 0 0;
//   border: 0;
//   border-radius: 0.25rem;
//   color: color(text-light);
//   width: 12rem !important;
//   text-align: center;
// }

// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link {
//   color: color(primary, base) !important;
//   background: white !important;

//   font-weight: 600;
//   border-bottom: 2px solid;
//   width: 9rem;
//   border-radius: 0;
//   text-align: center;
// }

// .nav-link:hover {
//   color: color(text-light) !important;
//   cursor: pointer;
// }

.flex-col {
  display: flex;
  flex-direction: column;
}

//cursor pointer

.cpntr {
  cursor: pointer;
}

a {
  // color: color(primary, base) !important   ;
}

.text-small {
  font-size: 13px;
  color: rgb(104, 104, 104);
  margin-bottom: 1rem;
}

.page-item.active .page-link {
  background-color: color(primary, base) !important;
  border-color: color(primary, base) !important;
  color: white !important;
}

.page-link {
  color: color(primary, base) !important;
}

element.style {
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}
*,
::after,
::before {
  box-sizing: border-box;
}
user agent stylesheet th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}
thead {
  background: color(secondary, base) !important;
  color: white;
}
