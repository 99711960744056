/* 2.3 Font weight*/
/* 2.1 Font Size*/
/* 3.0 Heading Size*/
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: 24px;
}

.h2 {
  font-size: 20px;
}

.color-primary {
  color: #000000;
}
.color-gray1 {
  color: #b3b3b3;
}
.color-gray2 {
  color: #cccccc;
}
.color-gray3 {
  color: #e6e6e6;
}
.color-gray4 {
  color: #f4f4f4;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.cpntr {
  cursor: pointer;
}

.text-small {
  font-size: 13px;
  color: rgb(104, 104, 104);
  margin-bottom: 1rem;
}

.page-item.active .page-link {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: white !important;
}

.page-link {
  color: #000000 !important;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

user agent stylesheet th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}

thead {
  background: #b8756d !important;
  color: white;
}

svg:not(:root) {
  overflow: unset;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

div {
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-weight: 400;
  background: #f8f8f8;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  margin-bottom: 0.1rem;
  color: #1d2a35;
  line-height: 150%;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 24px !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #1d2a35;
}

h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #1d2a35;
}

p {
  margin-top: 0;
  line-height: 170%;
  font-size: 400;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  color: unset;
}
a:hover {
  color: #b8756d;
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.form-check-input {
  cursor: pointer;
}

thead {
  background: #3cc13b;
  color: white;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: 14px;
  color: #1d2a35;
}

thead {
  border-style: hidden !important;
}

.strongUp {
  text-transform: uppercase;
  color: #b8756d;
}

.cpr {
  color: #f03738;
}

.cprt {
  color: #fcd7d7;
}

.bpr {
  background: #000000;
}

.bprt {
  background: #fcd7d7;
}

.cse {
  color: #b8756d;
}

.cset {
  color: #ffdfdb;
}

.bse {
  background: #b8756d;
}

.bset {
  background: #ffdfdb;
}

.csu {
  color: #3cc13b;
}

.bsu {
  background: #3cc13b;
}

.csut {
  color: #d7f3d7;
}

.bsut {
  background-color: #d7f3d7;
}

.cwa {
  color: #f3bb1c;
}

.bwa {
  background: #f3bb1c;
}

.cwat {
  color: #fdf1d1;
}

.bwat {
  background: #fdf1d1;
}

.cda {
  color: #f03738 !important;
}

.bda {
  background: #f03738;
}

.bdat {
  background: #fcd7d7;
}

.bcdat {
  background: #fcd7d7;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bton {
  font-weight: 400;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  margin: 0.5rem;
  transition: all 0.2s ease-in-out;
}
.bton--sm {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 34px;
}
.bton--md {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 39px;
}
.bton--lg {
  padding-left: 36px;
  padding-right: 36px;
  line-height: 39px;
}
.bton--full {
  margin: 0;
  width: 100%;
}
.bton--half {
  line-height: 48px;
  width: 50%;
}
.bton--primary {
  border-color: #000000;
  color: white;
  background-color: #000000;
}
.bton--primary:hover {
  background-color: #535353;
  border-color: #535353;
  color: white;
}
.bton--secondary {
  color: white;
  background-color: #b8756d;
  border-color: #b8756d;
}
.bton--secondary:hover {
  background-color: #ffae4e;
  border-color: #ffae4e;
  color: white;
}
.bton--ghost {
  border-radius: 4px;
  border-color: #000000;
  color: #000000;
  background-color: #ffffff;
}
.bton--ghost:hover {
  background-color: #535353;
  border-color: #535353;
  color: white;
}
.bton--ghost--danger {
  border-color: #fcd7d7;
  background: #fcd7d7;
  color: #f03738;
}
.bton--ghost--danger:hover {
  background-color: #f03738;
  border-color: #f03738;
  color: white;
}
.bton--danger {
  background: #f03738;
  color: white;
  border: #f03738;
}
.bton--nacked {
  border: none;
  color: #000;
  background: none;
  padding: 0;
}

.searchComp-input {
  height: 42px;
  margin-right: 5px;
  width: 25rem;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
}

.dateComp .dateInput {
  height: 42px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
  color: #383838;
}

.checkBox {
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0.3rem 1rem;
  color: #383838;
  display: inline-block;
}

section[role=notification] .dropdown-toggle::after {
  display: none !important;
}
section[role=notification] .btn {
  padding: 0;
  background: none;
  border: none;
}
section[role=notification] .show > .btn-success.dropdown-toggle {
  background-color: white !important;
}
section[role=notification] .dropdown-menu.show {
  width: 30rem;
  max-height: 70vh;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
  overflow-y: auto;
}

.searchComp-input {
  height: 42px;
  margin-right: 5px;
  width: 25rem;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
}

.dateComp .dateInput {
  height: 42px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
  color: #383838;
}

.checkBox {
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0.3rem 1rem;
  color: #383838;
  display: inline-block;
}

.projectWrapper {
  display: flex;
  justify-content: space-between;
}
.projectWrapper .sidebarWrapper {
  width: 17%;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: 0;
}
.projectWrapper .sidebarWrapper-brand {
  padding-top: 0.7rem;
  text-align: center;
}
.projectWrapper .sidebarWrapper-brand img {
  width: 10rem;
}
.projectWrapper .sidebarWrapper::-webkit-scrollbar {
  display: none;
}
.projectWrapper .contentWrapper {
  background: #f4fbfd;
  width: 83%;
}
.projectWrapper .contentWrapper .navBar {
  background: #ffffff;
  padding-right: 2rem;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.projectWrapper .contentWrapper .navBar .navBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3.8rem;
}
.projectWrapper .contentWrapper .navBar .navBox-items {
  padding: 0.8rem;
  font-weight: 500;
  font-size: 1.2rem;
}
.projectWrapper .contentWrapper .navBar .navBox-items .fa-user-circle {
  font-size: 1.5rem;
}
.projectWrapper .contentWrapper .navBar .navBox-items-notification {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  background: #e6e6e6;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projectWrapper .contentWrapper .navBar .navBox-items-notification i {
  font-size: 1.4rem;
  color: #000000;
}
.projectWrapper .contentWrapper .navBar .navBox-items-notification .notificationCounter {
  position: absolute;
  top: -6px;
  right: -6px;
  background: red;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 1.2rem;
  font-size: 1rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projectWrapper .contentWrapper .navBar .navBox .adminName {
  font-size: 16px;
}

.navList {
  margin: 0;
  padding: 0 0.5rem;
}
.navList-items {
  margin-bottom: 0.2rem;
}
.navList-items a {
  padding: 0.7rem 1rem;
  width: 100%;
  display: block;
  color: #1d2a35;
  font-weight: 500;
}
.navList-items a:hover {
  background: #535353;
  color: #ffffff;
}

.activeSidebar {
  background: #000000;
  color: white !important;
}
.activeSidebar:hover {
  background: #000000 !important;
  color: white !important;
}

.accordion-item {
  border: none !important;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: #535353 !important;
}

.accordion-button:hover {
  background: #535353 !important;
  color: #fff;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../assets/images/up.png") !important;
}

.statusCard {
  padding: 1rem 2rem;
  border-radius: 6px;
  margin-top: 2rem;
}
.statusCard-status {
  font-size: 22px;
}
.statusCard-number {
  font-size: 76px;
  text-align: center;
  font-weight: 600;
  margin-top: 0.5rem;
}

.notificationWrapper {
  padding: 1rem;
}
.notificationWrapper .notificationList {
  padding: 0;
}
.notificationWrapper .notificationList-day {
  color: rgb(104, 104, 104);
  text-align: right;
  font-weight: 300;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  cursor: pointer;
}
.notificationWrapper .notificationList-text {
  margin-top: 0.5rem;
  color: #000000;
}
.notificationWrapper .notificationList-time {
  color: #959595;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}

.dashboardWrapper {
  padding: 2rem;
  margin-left: 1rem;
  background: white;
}

.salesWrapper {
  padding: 2rem;
  margin-left: 1rem;
  margin-top: 1rem;
  background: white;
}

.GDNBox {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
  cursor: pointer;
}
.GDNBox-title {
  font-size: 18px;
  font-weight: 600;
}
.GDNBox-address {
  font-size: 14px;
  font-weight: 400;
  color: #383838;
}
.GDNBox-date {
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 400;
  color: #383838;
}

.POHistoryCard {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
  cursor: pointer;
}
.POHistoryCard-title {
  font-size: 18px;
  font-weight: 600;
}
.POHistoryCard-address {
  font-size: 14px;
  font-weight: 400;
  color: #383838;
}
.POHistoryCard-span {
  margin-top: 0.8rem;
  font-size: 14px;
  font-weight: 400;
  color: #383838;
}

.GDNWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.GDNWrapper .GDNTableWrapper {
  margin-top: 1.5rem;
}

.OrderPageWrapper {
  margin-top: 2rem;
}
.OrderPageWrapper .actionBar {
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.OrderPageWrapper .actionBar select {
  width: 15%;
  margin-left: 1rem;
}

.orderDetailsWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}

.cancelledOrder {
  visibility: 50% !important;
  background: rgb(255, 129, 129);
  opacity: 40%;
}

.trackerWrapper {
  display: flex;
  border: 1px solid rgb(230, 230, 230);
  padding: 1rem;
  text-align: center;
  justify-content: space-around;
  margin-top: 3rem;
}
.trackerWrapper i {
  background: rgb(138, 138, 138);
  color: white;
  padding: 1.5rem;
  font-size: 1.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.OrderHistoryWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.OrderHistoryWrapper .filters {
  display: flex;
  justify-content: flex-end;
}

.order-footer {
  display: flex;
  justify-content: flex-end;
}
.order-footer-main {
  width: 30%;
}

.ManCatWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.ManCatWrapper .MainCatCard {
  border: 1px solid rgb(230, 230, 230);
  margin-top: 2rem;
  padding: 0.5rem;
}
.ManCatWrapper .MainCatCard-image img {
  width: 100%;
  height: 7rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.ManCatWrapper .MainCatCard-image figcaption {
  margin-top: 0.5rem;
}
.ManCatWrapper .MainCatCard-text {
  height: 2rem;
  font-size: 14px;
}

.CatWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.CatWrapper .categories-tabs {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.CatWrapper .categories-tabs .active {
  cursor: pointer;
  padding: 1rem;
  background: #b8756d;
  border-radius: 6px;
  color: white;
  width: 50%;
  font-size: 13px;
}
.CatWrapper .categories-tabs .passive {
  cursor: pointer;
  border-radius: 6px;
  background: rgb(243, 243, 243);
  padding: 1rem;
  width: 50%;
  font-size: 13px;
}

.galleryWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.galleryWrapper .center {
  text-align: center;
  border: 1px dashed #000000;
  padding: 30px;
  margin: 15px 0;
}
.galleryWrapper .center span {
  margin-top: 3px;
}
.galleryWrapper .preview {
  height: 22rem;
  width: 100%;
  border: 1px solid rgb(230, 230, 230);
}
.galleryWrapper .preview img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.galleryWrapper .imageList {
  margin-top: 1rem;
  height: 11rem;
  width: 11rem;
  background: rgb(184, 184, 184);
  border: 1px solid rgb(230, 230, 230);
  position: relative;
  cursor: pointer;
}
.galleryWrapper .imageList img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.galleryWrapper .imageList .editIcon {
  position: absolute;
  text-align: center;
  top: 7px;
  right: 10px;
  background: white;
  border: 1px solid rgb(230, 230, 230);
  padding: 0.2rem;
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  cursor: pointer;
  color: rgb(49, 49, 49);
}
.galleryWrapper .imageList .deleteIcon {
  position: absolute;
  text-align: center;
  top: 7px;
  right: 5px;
  background: white;
  border: 1px solid rgb(230, 230, 230);
  padding: 0.2rem;
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  cursor: pointer;
  color: rgb(49, 49, 49);
}
.galleryWrapper .imageList .deleteIcon i {
  color: #f03738;
}

.preview img {
  width: 100%;
}

.descProduct {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.descProduct .Desclist ul {
  padding: 0;
}
.descProduct .Desclist-items {
  padding: 0.5rem;
}
.descProduct .Desclist-items i {
  cursor: pointer;
}
.descProduct .tagsHolder {
  border: 1px solid rgb(230, 230, 230);
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.descProduct .tagsHolder .tagPills {
  display: inline-flex;
  padding: 0.5rem;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 6px;
}

.ProductsWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.ProductsWrapper .products-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ProductsWrapper .productSearch {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.ProductsWrapper .productSearch-input {
  width: 300px;
  height: 38px;
}
.ProductsWrapper .productSearch-bton {
  border-radius: 4px;
  padding: 0 1.5rem;
  border: 1px solid #000000;
  color: #000000;
  background-color: transparent;
}
.ProductsWrapper .productSearch-bton:hover {
  background-color: #535353;
  border-color: #535353;
  color: white;
}
.ProductsWrapper .productFilter {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
}
.ProductsWrapper .tableHolder .productList {
  display: flex;
}
.ProductsWrapper .tableHolder .productList-image {
  background: rgb(241, 241, 241);
  border: 1px solid rgb(230, 230, 230);
  margin: 0;
}
.ProductsWrapper .tableHolder .productList-image img {
  height: 6rem;
  width: 6rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.ProductsWrapper .tableHolder .productList-name {
  margin-left: 0.5rem;
}

.sliderHolder {
  border: 1px solid rgb(230, 230, 230);
  background: white;
  padding: 1rem;
  height: 100%;
  margin-top: 1rem;
}
.sliderHolder-image {
  height: 12rem;
  width: 100%;
}
.sliderHolder-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.sliderHolder .linkCarousel {
  margin-top: 1rem;
  text-decoration: underline;
  height: 3rem;
  overflow: hidden;
  margin-bottom: 1rem;
}

.relatedDropdown {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 10px;
  font-size: 15px;
  cursor: pointer;
}
.relatedDropdown:hover {
  transform: scale(1.01);
}

.variants {
  display: flex;
  flex-direction: column;
}
.variants_sub {
  font-size: 0.8rem;
  color: #626262;
}
.variants-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
}
.variants-popup_overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}
.variants-popup_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  z-index: 200;
}
.variants-update {
  z-index: 50;
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem 1.3rem 1.3rem 1.3rem;
  width: 280px;
  border-radius: 8px;
}
.variants-update_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.variants-update_header button {
  background-color: transparent;
  border: none;
  outline: none;
}
.variants-update_main {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.variants-update_label {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}
.variants-update_delete {
  display: flex;
  flex-direction: column;
}
.variants-update_delete .delete_label {
  color: #f03738;
  font-weight: 500;
}
.variants-update_delete .delete_sub {
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
}
.variants-update_delete .delete_sub span {
  font-weight: 500;
}
.variants-delete {
  z-index: 50;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem 1.3rem 1.5rem 1.3rem;
  border-radius: 8px;
  width: 420px;
}
.variants-delete_header {
  display: flex;
  justify-content: flex-end;
}
.variants-delete_header button {
  background-color: transparent;
  border: none;
  outline: none;
}
.variants-delete_main {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.variants-delete_label {
  text-align: center;
  font-weight: 500;
}
.variants-delete_btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.variants-delete_btns .confirm-btn {
  margin: 0;
  width: 100px;
  background: transparent;
  border: 1px solid #f03738;
  color: #f03738;
}
.variants-delete_btns .confirm-btn:hover {
  background-color: #fcd7d7;
}
.variants-delete_btns .cancel-btn {
  margin: 0;
  width: 100px;
  background: #b8756d;
  border: 1px solid #b8756d;
  color: #ffffff;
}
.variants-delete_btns .cancel-btn:hover {
  background-color: #ffae4e;
  border-color: #ffae4e;
}
.variants-upload {
  z-index: 50;
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem 1.3rem 1.3rem 1.3rem;
  width: 675px;
  border-radius: 8px;
}
.variants-upload_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.variants-upload_header button {
  background-color: transparent;
  border: none;
  outline: none;
}
.variants-upload_main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1.5rem;
}
.variants-upload_btn {
  width: 140px;
  height: 140px;
  border-radius: 8px;
  border: 1px dashed #cccccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.variants-upload_btn span {
  color: #1058c5;
  font-size: 0.8rem;
}
.variants-upload_img {
  width: 140px;
  height: 140px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
  background-color: transparent;
}
.variants-upload_img img {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.variants-upload .selected {
  border: 2px solid black;
}
.variants-upload_btns {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.variants-upload_btns button {
  font-size: 14px;
}
.variants-upload_btns .bton--nacked {
  color: #000;
}

.variant {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 200px;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 1rem;
}
.variant-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.variant-header span {
  font-size: 1.1rem;
  font-weight: 500;
}
.variant-header .edit-btn {
  border: none;
  outline: none;
  font-size: 15px;
  color: #1058c5;
  background-color: transparent;
}
.variant-subtype {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.variant-subtype_input {
  padding-right: 50px;
}
.variant-subtype_btn {
  position: absolute;
  right: 10px;
  background-color: transparent;
  border: none;
  outline: none;
}
.variant-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.variant-list_item {
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem 0.75rem;
}
.variant-list_item-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
}
.variant-list_item-icons .edit {
  background-color: transparent;
  border: none;
  outline: none;
  color: #383838;
  padding: 0;
}
.variant-list_item-icons .dlt {
  background-color: transparent;
  border: none;
  outline: none;
  color: #f03738;
  padding: 0;
}

.variant-products_table {
  margin-top: 1rem;
  padding: 0.5rem !important;
}
.variant-products_table_head {
  background-color: transparent !important;
  color: #000;
}
.variant-products_table_row {
  border-bottom: 1px solid #cccccc;
}
.variant-products_table_th {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0 0.5rem !important;
}
.variant-products_table_td {
  min-width: 100px;
  padding: 1rem 0.5rem !important;
}
.variant-products_table_image {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #cccccc;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  padding: 0;
}
.variant-products_table_image img {
  max-height: 100%;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.variant-products_table_image i {
  color: #383838;
}
.variant-products_table_input input {
  font-size: 14px !important;
  width: 100px;
}

.createadminWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}

.adminWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}

.transactionWrapper {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.transactionWrapper .transFilter {
  display: flex;
  justify-content: flex-end;
}
.transactionWrapper .transFilter select {
  width: 20%;
}
.transactionWrapper .transTable {
  margin-top: 1rem;
}

.loginBack {
  background: rgb(245, 245, 245);
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.LoginWrapper {
  width: 30rem;
  overflow: hidden;
  background: white;
  padding: 1rem;
  border-radius: 6px;
  margin: 5rem auto;
}
.LoginWrapper img {
  width: 12rem;
}

.uploadedImage {
  height: 10rem;
  border: 1px solid rgb(230, 230, 230);
  padding: 0.5rem;
}
.uploadedImage img {
  height: 8rem;
  width: 8rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.promoWrappper {
  padding-top: 2rem;
}
.promoWrappper .promoConatiner {
  background: #ffffff;
  padding: 0.8rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
.promoWrappper .promoConatiner-inputs {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.promoWrappper .promoConatiner-inputs input {
  width: 34%;
  padding: 0 0.8rem;
  height: 2.5rem;
}
.promoWrappper .promoConatiner-list {
  padding: 0;
}
.promoWrappper .promoConatiner-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  border-bottom: 1px solid rgb(218, 218, 218);
  padding: 0.5 0;
}

.loadingHere {
  text-align: center;
  display: grid;
  place-items: center;
  padding-top: 2rem;
  height: 70vh;
}

.loader {
  width: 100px;
  height: 100px;
  position: relative;
}

.loader div {
  position: absolute;
  width: 15%;
  height: 15%;
  border-radius: 5px;
  animation: load 1s infinite ease-in-out;
  border: none;
}

.loader div:nth-of-type(1) {
  background-color: #000000;
}

.loader div:nth-of-type(2) {
  background-color: #b8756d;
  animation-delay: 0.5s;
}

.loader div:nth-of-type(3) {
  background-color: #f8cb2e;
  animation-delay: 1s;
}

.loader div:nth-of-type(4) {
  background-color: #006e7f;
  animation-delay: 1.5s;
}

@keyframes load {
  0% {
    transform: translate(0%);
    border-radius: 50%;
  }
  25% {
    transform: translate(200%) rotate(45deg);
    border-radius: 0%;
  }
  50% {
    transform: translate(200%, 200%);
    border-radius: 50%;
  }
  75% {
    transform: translate(0, 200%) rotate(-45deg);
    border-radius: 0%;
  }
  100% {
    transform: translate(0%);
    border-radius: 50%;
  }
}
.page-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.enquiry-product {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.enquiry-product-image {
  height: 8rem;
  width: 8rem;
}
.enquiry-product-image img {
  height: 100%;
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.sort-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
}
.sort-popup_overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}
.sort-cat {
  z-index: 50;
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem 1.3rem 1.3rem 1.3rem;
  width: 675px;
  border-radius: 8px;
}
.sort-cat_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sort-cat_header button {
  background-color: transparent;
  border: none;
  outline: none;
}
.sort-cat_main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1.5rem;
}
.sort-cat_main .card-box {
  border: 1px dashed gray;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
  cursor: move;
}
.sort-cat_btns {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.sort-cat_btns button {
  font-size: 14px;
}
.sort-cat_btns .bton--nacked {
  color: #000;
}

.testimonial {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid rgb(230, 230, 230);
  background: white;
  padding: 1rem;
  height: 100%;
  margin-top: 1rem;
}
.testimonial-header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.testimonial-image {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
}
.testimonial-image img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.testimonial-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.testimonial-name {
  font-size: 1.125rem;
  font-weight: 500;
}
.testimonial-role {
  font-size: 0.9rem;
  color: #383838;
}
.testimonial-message {
  font-size: 0.9rem;
  line-height: 160%;
}

.web {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  margin-bottom: 8rem;
}
.web .input-error {
  color: red;
  font-size: 14px;
}
.web-header {
  position: sticky;
  top: 0rem;
  display: flex;
  flex-direction: column;
  background-color: #f4fbfd;
  padding: 0.5rem;
  padding-right: 1rem;
  z-index: 50;
}
.web-title {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}
.web-desc {
  color: #383838;
  font-size: 0.825rem;
  max-width: 60%;
}
.web-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.web-form_item {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.web-form_item--header {
  display: flex;
  flex-direction: column;
}
.web-form_item--title {
  font-size: 1.25rem;
  color: #1d2a35;
  font-weight: 500;
}
.web-form_item--desc {
  font-size: 0.825rem;
  color: #959595;
}
.web-form_item--inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.web-form_img {
  height: 15rem;
  margin-top: 1rem;
}
.web-form_img img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.web-form_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.web-form_lhead {
  display: flex;
  flex-direction: column;
}
.web-form_label {
  font-weight: 500;
}
.web-form_desc {
  font-size: 0.825rem;
  color: #959595;
}
.web-form_input {
  resize: none !important;
  font-size: 0.85rem !important;
}

.appt {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  margin-bottom: 8rem;
}
.appt .input-error {
  color: red;
  font-size: 14px;
}
.appt-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: #f4fbfd;
  padding: 0.5rem;
  padding-right: 1rem;
  z-index: 50;
}
.appt-title {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}
.appt-desc {
  color: #383838;
  font-size: 0.825rem;
  max-width: 60%;
}
.appt-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.appt-form_item {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.appt-form_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.appt-form_lhead {
  display: flex;
  flex-direction: column;
}
.appt-form_label {
  font-weight: 500;
}
.appt-form_indiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: baseline;
}
.appt-form_input {
  resize: none !important;
  font-size: 0.85rem !important;
  max-height: 40px !important;
}/*# sourceMappingURL=App.css.map */