.sort {
    &-popup {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        width: 100%;

        &_overlay {
            background-color: rgba(0, 0, 0, 0.2);
            position: fixed;
            height: 100%;
            width: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 30;
        }
    }
    &-cat {
        z-index: 50;
        display: flex;
        gap: 0.8rem;
        flex-direction: column;
        background-color: #fff;
        padding: 1rem 1.3rem 1.3rem 1.3rem;
        width: 675px;
        border-radius: 8px;

        &_header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            button {
                background-color: transparent;
                border: none;
                outline: none;
            }
        }

        &_main {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 1.5rem;

            .card-box {
                border: 1px dashed gray;
                padding: 0.5rem 1rem;
                margin-bottom: 0.5rem;
                background-color: white;
                cursor: move;
            }
        }

        &_btns {
            align-self: flex-end;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            button {
                font-size: 14px;
            }

            .bton--nacked {
                color: #000;
            }
        }
    }
}
